
















































.journal-button {
  position: absolute;
  top: 10%;
  right: -4%;
  width: 171px;
  height: 111px;
  max-width: 12.4%;
  max-height: 13.1vh;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: scroll;

  &:hover,
  &.active {
    right: -7%;
  }
}

.characters {
  background-image: url('~assets/img/journal/buttons/npc.png');
}

.artifacts {
  top: 27%;
  background-image: url('~assets/img/journal/buttons/artifacts.png');
}

.book {
  top: 44%;
  background-image: url('~assets/img/journal/buttons/book.png');
}

.map {
  top: 61%;
  background-image: url('~assets/img/journal/buttons/map.png');
}

.exit-journal {
  top: 78%;
  background-image: url('~assets/img/journal/buttons/close.png');
}
