








































































































































































































































.journal-wrapper {
  background: url('~assets/img/journal/journal-frame-bg.png') no-repeat scroll;
  box-sizing: border-box;
  margin: 8vh auto;
  z-index: 99;
  position: relative;
  background-size: 100% auto;
}

.pages-container {
  position: absolute;
  top: 4%;
  right: 3%;
  bottom: 4%;
  left: 3%;
}
